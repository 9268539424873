<template>
  <div class="container">
    <div class="row">
      <div class="content">
        <router-link href to="/travel">
          <div class="prv_b"></div>
        </router-link>
        <router-link href to="/privilege">
          <div class="nxt_b"></div>
        </router-link>
        <h1>Загальна муніципальна картка вінничанина</h1>
        <div class="row row-card">
          <div class="col-md-6 col-card">
            <div class="row">
              <div class="col-md-5 midd">
                <img src="../../../../assets/Card_New.png" />
                <div class="person">
                  <span class="icon-personalization"></span>Персоніфікована
                </div>
              </div>
              <div class="col-md-7">
                <ul>
                  <li>
                    <span class="icon-lowered"></span>Знижена вартість проїзду
                    за проїзними
                  </li>
                  <li>
                    <span class="icon-transfer"></span>З пересадкою протягом 30
                    хв
                  </li>
                  <li>
                    <span class="icon-balance-recovery"></span>Відновлення
                    балансу при втраті картки
                  </li>
                  <li>
                    <span class="icon-calendar"></span>Термін дії проїзного - 30
                    календарних днів
                  </li>
                  <li>
                    <span class="icon-purse"></span>Гаманець для поповнення
                    рахунку
                  </li>
                  <li>
                    <span class="icon-making"></span>Виготовлення - від 30 днів.
                    Вартість - 60 грн.
                  </li>
                  <li>
                    <span class="icon-payment"></span>Оплата - безготівкова
                  </li>
                  <li>
                    <span class="icon-usage-term"></span>Термін використання - 5
                    років
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <div class="icon-group">
              <span class="icon-tram"></span>
              <span class="icon-trolley-bus"></span>
              <span class="icon-bus"></span>
            </div>
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td>Проїзний "Безлім"</td>
                  <td>&nbsp;</td>
                  <td>1125 грн.</td>
                </tr>
                <tr>
                  <td>Проїзний</td>
                  <td>
                    130
                    <span>поїздок</span>
                  </td>
                  <td>750 грн.</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <div class="row row-purse">
              <div class="col-md-4">
                <span class="icon-purse"></span>
                <div class="purse">Гаманець</div>
              </div>
              <div class="col-md-7">
                <div class="price">
                  <div class="price">
                    <span class="icon-tram"></span>
                    <div>12 грн.</div>
                  </div>
                  <div class="price">
                    <span class="icon-trolley-bus"></span>
                    <div>12 грн.</div>
                  </div>
                  <div class="price">
                    <span class="icon-bus"></span>
                    <div>15 грн.</div>
                  </div>
                </div>
                <div class="minibus">
                  <span class="icon-minibus"></span>
                  <div>
                    15/18 грн., без пересадки
                    <span>приватні перевізники</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-card">
            <div class="about-card">
              <p>
                <span>Загальна муніципальна картка вінничанина</span>– це
                персоніфікована картка, яка забезпечує можливість проїзду у
                муніципальному транспорті мешканцям та гостям міста.
              </p>
              <p>
                МКВ для оплати проїзду у міському громадському транспорті надалі
                дозволить користуватись іншими муніципальними послугами.
              </p>
              <hr />
              <p>
                У випадку, коли заявник, який досягнув 18-річного віку, не в
                змозі особисто надати необхідні документи, отримати електронний
                квиток та/або особисто бути присутнім, його інтереси може
                представляти представник. Повноваження представника
                підтверджується нотаріально посвідченою довіреністю.
                <!-- <a href="/online-queue">
                                                Детальніше...
                </a>-->
              </p>
              <p>
                Довіреність військовослужбовця або іншої особи, яка перебуває на
                лікуванні у госпіталі, санаторії та іншому
                військово-лікувальному закладі, може бути посвідчена начальником
                цього закладу, його заступником з медичної частини, старшим або
                черговим лікарем.
              </p>
              <p>
                Довіреність військовослужбовця, а в пунктах дислокації
                військової частини, з'єднання, установи, військово-навчального
                закладу, де немає нотаріуса чи органу, що вчиняє нотаріальні
                дії, а також довіреність робітника, службовця, члена їхніх сімей
                і члена сім'ї військовослужбовця може бути посвідчена командиром
                (начальником) цих частини, з'єднання, установи або закладу.
              </p>
              <p>
                Довіреність особи, яка перебуває у місці позбавлення волі
                (слідчому ізоляторі), може бути посвідчена начальником місця
                позбавлення волі.
              </p>
              <p>
                Довіреності, посвідчені зазначеними посадовими особами,
                прирівнюються до нотаріально посвідчених.
              </p>
              <hr />
              <span>Як отримати загальну МКВ</span>

              <ol class="order-card-flow">
                <li>
                  <div class="li-inner">
                    <span>Замовити картку.</span>
                    Замовлення здійснюється
                    <a href="/order-card">онлайн</a>
                    або у
                    <a href="/service-center">центрі обслуговування.</a>

                       <p class="docs-sublist__header">
                      У
                      <span>центрі обслуговування</span> відповідно до черги
                      звернутись до консультанта та показати оригінали:
                    </p>
                    <ul class="docs-sublist">
                      <li>
                        - документа, що посвідчує особу заявника (паспорт або ID
                        картку);
                      </li>
                      <li>
                        - ідентифікаційного номера заявника, або довідки про
                        відмову.
                      </li>
                    </ul>
                    <p class="mt-3">
                      Консультант зробить фото заявника та оформить заяву на
                      отримання картки.
                    </p>
                    <hr />
                    <p class="docs-sublist__header">
                      Якщо картку замовляє
                      <span
                        >представник заявника, який досягнув 18-ти річного
                        віку,</span
                      >
                      представнику необхідно звернутись до консультанта та
                      показати оригінали:
                    </p>
                    <ul class="docs-sublist">
                      <li>
                        - нотаріально посвідченої довіреності представника;
                      </li>
                      <li>
                        - документа, що посвідчує особу представника (паспорту
                        або ID картки);
                      </li>
                      <li>
                        - документа, що посвідчує особу заявника (паспорт або ID
                        картку);
                      </li>
                      <li>
                        - ідентифікаційний номер заявника, або довідку про
                        відмову.
                      </li>
                      <li>- фото заявника, як на документ.</li>
                    </ul>
                    <p class="mt-3">
                      Консультант оформить заяву на отримання картки.
                    </p>
                  </div>
                </li>
                <li>
                  <div class="li-inner">
                    <span
                      >Отримати лист на електронну пошту та оплатити вартість
                      картки </span>
                    <a href="/4ec"> на сайті</a>
                    або у терміналі.
                  </div>
                </li>
                <li>
                  <div class="li-inner">
                    <span>Отримати підтвердження про виготовлення картки</span>
                    у SMS або листі на електронній пошті.
                  </div>
                </li>
                <li>
                  <div class="li-inner">
                    <span>Отримати загальну МКВ.</span>
                    Прийти у обраний день та час у центр обслуговування,
                    звернутись до консультанта та показати:
                    <ul class="docs-sublist">
                      <li>
                        - документ, що посвідчує особу заявника(паспорт або ID
                        картку);
                      </li>
                      <li>- ідентифікаційний номер заявника.</li>
                    </ul>
                    <p class="docs-sublist__header">
                      Якщо картку отримує
                      <span>представник заявника</span>
                      показати:
                    </p>
                    <ul class="docs-sublist">
                      <li>
                        - нотаріально посвідчену довіреність представника (якщо
                        заявник, досягнув 18-ти річного віку).
                      </li>
                      <li>
                        - документ, що посвідчує особу представника заявника;
                      </li>
                      <li>- документ, що посвідчує особу заявника;</li>
                      <li>- ідентифікаційний номер заявника.</li>
                    </ul>
                  </div>
                </li>
              </ol>
            </div>
            <div class="order-btn">
              <base-button type="button" class="btn" @click="orderCard(3)"
                >Замовити онлайн</base-button
              >
              <!--
              <base-button
                type="button"
                class="btn"
                @click="navigateUrl('/online-queue', '1')"
                >Записатись до електронної черги</base-button
              >
            -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderCardMixin from "../orderCardsMixin";
import BaseButton from "../../../BaseButton";

export default {
  mixins: [orderCardMixin],
  components: {
    BaseButton,
  },
};
</script>

<style scoped lang="scss" src="./styles.scss"></style>
<style scoped lang="scss" src="../commonStyles.scss"></style>
