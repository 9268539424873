<template>
  <div class="container">
    <div class="row">
      <div class="content">
        <router-link href to="/privilege">
          <div class="prv_b"></div>
        </router-link>
        <router-link href to="/student">
          <div class="nxt_b"></div>
        </router-link>
        <h1>Учнівська муніципальна картка вінничанина</h1>
        <div class="row row-card">
          <div class="col-md-6 col-card">
            <div class="row">
              <div class="col-md-5 midd">
                <img src="../../../../assets/Card_New.png" />
                <div class="person">
                  <span class="icon-personalization"></span>Персоніфікована
                </div>
              </div>
              <div class="col-md-7">
                <ul>
                  <li>
                    <span class="icon-lowered"></span>Знижена вартість проїзду
                    за проїзним
                  </li>
                  <li>
                    <span class="icon-transfer"></span>З пересадкою протягом 30
                    хв.
                  </li>
                  <li>
                    <span class="icon-balance-recovery"></span>Відновлення
                    балансу при втраті картки
                  </li>
                  <li>
                    <span class="icon-calendar"></span>Термін дії проїзного - 30
                    календарних днів
                  </li>
                  <li>
                    <span class="icon-purse"></span>Гаманець для поповнення
                    рахунку
                  </li>
                  <li>
                    <span class="icon-making"></span> Виготовлення - від 30
                    днів. Вартість - 60 грн.
                  </li>
                  <li>
                    <span class="icon-payment"></span>Оплата - безготівкова
                  </li>
                  <li>
                    <span class="icon-usage-term"></span>Термін використання -
                    протягом навчання
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <div class="icon-group">
              <span class="icon-tram"></span>
              <span class="icon-trolley-bus"></span>
              <span class="icon-bus"></span>
            </div>
            <table class="table">
              <tbody>
                <tr>
                  <td>Проїзний</td>
                  <td>
                    130
                    <span>поїздок</span>
                  </td>
                  <td>225 грн.</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <div class="row row-purse">
              <div class="col-md-4">
                <span class="icon-purse"></span>
                <div class="purse">Гаманець</div>
              </div>
              <div class="col-md-7">
                <div class="price">
                  <div class="price">
                    <span class="icon-tram"></span>
                    <div>12 грн.</div>
                  </div>
                  <div class="price">
                    <span class="icon-trolley-bus"></span>
                    <div>12 грн.</div>
                  </div>
                  <div class="price">
                    <span class="icon-bus"></span>
                    <div>15 грн.</div>
                  </div>
                </div>
                <div class="minibus">
                  <span class="icon-minibus"></span>
                  <div>
                    15/18 грн., без пересадки
                    <span>приватні перевізники</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-card">
            <div class="about-card">
              <p>
                <span>Учнівська муніципальна картка вінничанина</span>
                - це персоніфікована картка, яка забезпечує можливість проїзду у
                муніципальному транспорті за пільговим тарифом, для учнів
                гімназій, ліцеїв та шкіл Вінниці.
              </p>
              <p>
                Учнівські МКВ для оплати проїзду у міському громадському
                транспорті надалі дозволять користуватись іншими муніципальними
                послугами.
              </p>
              <p>
                Учню картку оформляє його представник (мати, батько або опікун).
                Підпис представника підтверджується шляхом здійснення оплати
                картки.
              </p>
              <p>
                <span>Як отримати учнівську МКВ</span>
              </p>
              <ol class="order-card-flow">
                <li>
                  <div class="li-inner">
                    <span>Замовити картку.</span>
                    Замовлення здійснюється
                    <a href="/order-card">онлайн</a>
                    або у
                    <a href="/service-center">центрі обслуговування</a>.
                   
                   <!--
                     <p class="docs-sublist__header">
                      Перед відвідуванням
                      <a href="/service-center">центру обслуговування</a>
                      Ви можете завчасно
                      <a href="/online-queue"
                        >записатись до електронної черги</a
                      >
                      на цьому сайті. Роздрукуйте талон та покажіть його у
                      центрі обслуговування адміністратору. При відсутності
                      талону зареєструйтесь у електронній черзі на місці.
                    </p>
                  -->
                    <p class="docs-sublist__header">
                      При замовленні
                      <span>у центрі обслуговування</span>
                      потрібно показати:
                    </p>
                    <ul class="docs-sublist">
                      <li>
                        - документ, що посвідчує особу учня (свідоцтво про
                        народження або ID картку);
                      </li>
                      <li>- ідентифікаційний номер учня, якщо є;</li>
                      <li>
                        - документ, що підтверджує право на пільгу (учнівський
                        квиток);
                      </li>
                      <li>
                        - документ, що посвідчує особу представника (паспорт або
                        ID картку);
                      </li>
                      <li>
                        - ідентифікаційний номер представника або довідку про
                        відмову.
                      </li>
                      <li>- за бажанням, фото учня, як на документи;</li>
                      <li>
                        - номер контактного телефону та адресу електронної пошти
                        представника учня.
                      </li>
                    </ul>
                    <p class="mt-3">
                      Консультант зробить фото заявника та оформить заяву на
                      отримання картки.
                    </p>
                                      </div>
                </li>
                <li>
                  <div class="li-inner">
                    <span
                      >Отримати лист на електронну пошту та оплатити вартість
                      картки</span
                    >
                    <a href="/4ec">на сайті</a>
                    або у терміналі.
                  </div>
                </li>
                <li>
                  <div class="li-inner">
                    <span>Отримати підтвердження про виготовлення картки</span>
                    у SMS або листі на електронній пошті.
                  </div>
                </li>
                <li>
                  <div class="li-inner">
                    <span>Отримати учнівську МКВ.</span>
                    <p class="docs-sublist__header">
                      Представнику учня потрібно прийти у обраний день та час у
                      центр обслуговування, звернутись до консультанта та
                      показати:
                    </p>
                    <ul class="docs-sublist">
                      <li>- документ, що посвідчує особу учня;</li>
                      <li>- ідентифікаційний номер учня, якщо є;</li>
                      <li>
                        - документ, що посвідчує пільгу (учнівський квиток).
                      </li>
                      <li>- документ, що посвідчує особу представника учня;</li>
                      <li>- ідентифікаційний номер представника учня.</li>
                    </ul>
                    
                  </div>
                </li>
              </ol>
            </div>
            <div class="order-btn">
              <base-button type="button" class="btn" @click="orderCard(2)"
                >Замовити онлайн</base-button
              >
              <!--
              <base-button
                type="button"
                class="btn"
                @click="navigateUrl('/online-queue', '1')"
                >Записатись до електронної черги</base-button
              >
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderCardMixin from "../orderCardsMixin";
import BaseButton from "../../../BaseButton";

export default {
  mixins: [orderCardMixin],
  components: {
    BaseButton,
  },
};
</script>

<style scoped lang="scss" src="./styles.scss"></style>
<style scoped lang="scss" src="../commonStyles.scss"></style>
