<template>
  <div class="container">
    <div class="row">
      <div class="content">
        <router-link href to="/pupillary">
          <div class="prv_b"></div>
        </router-link>
        <router-link href to="/travel">
          <div class="nxt_b"></div>
        </router-link>
        <h1>Студентська муніципальна картка вінничанина</h1>
        <div class="row row-card">
          <div class="col-md-6 col-card">
            <div class="row">
              <div class="col-md-5 midd">
                <img src="../../../../assets/Card_New.png" />
                <div class="person">
                  <span class="icon-personalization"></span>Персоніфікована
                </div>
              </div>
              <div class="col-md-7">
                <ul>
                  <li>
                    <span class="icon-lowered"></span>Знижена вартість проїзду
                    за проїзним
                  </li>
                  <li>
                    <span class="icon-transfer"></span>З пересадкою протягом 30
                    хв.
                  </li>
                  <li>
                    <span class="icon-balance-recovery"></span>Відновлення
                    балансу при втраті картки
                  </li>
                  <li>
                    <span class="icon-calendar"></span>Термін дії проїзного - 30
                    календарних днів
                  </li>
                  <li>
                    <span class="icon-purse"></span>Гаманець для поповнення
                    рахунку
                  </li>
                  <li>
                    <span class="icon-making"></span>Виготовлення - від 30 днів.
                    Вартість - 60 грн.
                  </li>
                  <li>
                    <span class="icon-payment"></span>Оплата - безготівкова
                  </li>
                  <li>
                    <span class="icon-usage-term"></span>Термін використання -
                    протягом навчання
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <div class="icon-group">
              <span class="icon-tram"></span>
              <span class="icon-trolley-bus"></span>
              <span class="icon-bus"></span>
            </div>
            <table class="table">
              <tbody>
                <tr>
                  <td>Проїзний</td>
                  <td>
                    130
                    <span>поїздок</span>
                  </td>
                  <td>375 грн.</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <div class="row row-purse">
              <div class="col-md-4">
                <span class="icon-purse"></span>
                <div class="purse">Гаманець</div>
              </div>
              <div class="col-md-7">
                <div class="price">
                  <div class="price">
                    <span class="icon-tram"></span>
                    <div>12 грн.</div>
                  </div>
                  <div class="price">
                    <span class="icon-trolley-bus"></span>
                    <div>12 грн.</div>
                  </div>
                  <div class="price">
                    <span class="icon-bus"></span>
                    <div>15 грн.</div>
                  </div>
                </div>
                <div class="minibus">
                  <span class="icon-minibus"></span>
                  <div>
                    15/18 грн., без пересадки
                    <span>приватні перевізники</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-card">
            <div class="about-card">
              <p>
                <span>Студентська муніципальна картка вінничанина</span>
                - це персоніфікована картка, яка забезпечує можливість проїзду у
                муніципальному транспорті за пільговим тарифом, для учнів та
                студентів денної форми навчання освітніх установ Вінниці I-IV
                рівнів акредитації.
              </p>
              <p>
                Студентські МКВ для оплати проїзду у міському громадському
                транспорті надалі дозволять користуватись іншими муніципальними
                послугами.
              </p>
              <p>
                Учню/студенту, який не досягнув 18-річного віку, картку оформляє
                його представник (мати, батько або опікун). Підпис представника
                підтверджується шляхом здійснення оплати картки.
              </p>
              <hr />
              <p>
                У випадку, коли учень/студент, який досягнув 18-річного віку, не
                в змозі особисто надати необхідні документи, отримати
                електронний квиток та/або особисто бути присутнім, його інтереси
                може представляти представник. Повноваження представника
                підтверджується нотаріально посвідченою довіреністю.
                <!--<a href="/online-queue">
                                    Детальніше...
                </a>-->
              </p>
              <p>
                Довіреність військовослужбовця або іншої особи, яка перебуває на
                лікуванні у госпіталі, санаторії та іншому
                військово-лікувальному закладі, може бути посвідчена начальником
                цього закладу, його заступником з медичної частини, старшим або
                черговим лікарем.
              </p>
              <p>
                Довіреність військовослужбовця, а в пунктах дислокації
                військової частини, з'єднання, установи, військово-навчального
                закладу, де немає нотаріуса чи органу, що вчиняє нотаріальні
                дії, а також довіреність робітника, службовця, члена їхніх сімей
                і члена сім'ї військовослужбовця може бути посвідчена командиром
                (начальником) цих частини, з'єднання, установи або закладу.
              </p>
              <p>
                Довіреність особи, яка перебуває у місці позбавлення волі
                (слідчому ізоляторі), може бути посвідчена начальником місця
                позбавлення волі.
              </p>
              <p>
                Довіреності, посвідчені зазначеними посадовими особами,
                прирівнюються до нотаріально посвідчених.
              </p>
              <hr />
              <p>
                <span>Як отримати студентську МКВ</span>
              </p>
              <ol class="order-card-flow">
                <li>
                  <div class="li-inner">
                    <span>Замовити картку.</span>
                    Замовлення здійснюється
                    <a href="/order-card">онлайн</a>
                    або у
                    <a href="/service-center">центрі обслуговування,</a>
                    або у представника освітньої установи (куратора групи,
                    методиста).
                    <br />
                    <hr />
                    Замовлення
                    <span>онлайн</span>
                    можливе тільки при наявності документу, що підтверджує
                    пільгу, державного зразка:
                    <br />
                    <p class="student-icons">
                      <span class="student-icons__img-wrap">
                        <img
                          src="../../../../assets/pupilTicket.png"
                          class
                          alt="pupil card"
                        />
                      </span>
                      учнівського квитка для учнів профтех училищ;
                    </p>
                    <p class="student-icons">
                      <span class="student-icons__img-wrap">
                        <img
                          src="../../../../assets/studentTicket.png"
                          class
                          alt="student card"
                        />
                      </span>
                      студентського квитка для студентів.
                    </p>
                    <hr />
                    
                    <!--
                    <p class="docs-sublist__header">
                      Перед відвідуванням
                      <a href="/service-center">центру обслуговування</a>
                      Ви можете завчасно
                      <a href="/online-queue"
                        >записатись до електронної черги</a
                      >
                       на цьому сайті. Роздрукуйте талон та покажіть його у
                      Центрі обслуговування адміністратору. При відсутності
                      талону зареєструйтесь у електронній черзі на місці.
                    </p>
                    -->
                    <p class="docs-sublist__header">
                      При
                      <span
                        >замовленні у центрі обслуговування учню/студенту, який
                        досягнув 18-ти річного віку</span
                      >
                      необхідно показати:
                    </p>
                    <ul class="docs-sublist">
                      <li>
                        - документ, що посвідчує особу учня/студента (паспорт
                        або ID картку);
                      </li>
                      <li>
                        - ідентифікаційний номер учня/студента, якщо є, або
                        довідку про відмову;
                      </li>
                      <li>
                        - документ, що підтверджує право на пільгу (учнівський
                        або студентський квиток);
                      </li>
                      <li>
                        - номер контактного телефону та адресу електронної пошти
                        учня/студента (якщо учень/студент досягнув 18-ти річного
                        віку).
                      </li>
                    </ul>
                    <p class="docs-sublist__header">
                      Якщо картку замовляє
                      <span
                        >представник учня/студента, який не досягнув 18-ти
                        річного віку,</span
                      >
                      крім вищеперелічених додатково показати:
                    </p>
                    <ul class="docs-sublist">
                      <li>
                        - документ, що посвідчує особу представника (паспорт або
                        ID картку);
                      </li>
                      <li>
                        - ідентифікаційний номер представника або довідку про
                        відмову;
                      </li>
                      <li>
                        - фото, як на документ, учня/студента (якщо
                        учень/студент не в змозі особисто бути присутнім);
                      </li>
                      <li>
                        - номер контактного телефону та адресу електронної пошти
                        представника.
                      </li>
                    </ul>
                    <p class="docs-sublist__header">
                      Якщо картку замовляє
                      <span
                        >представник учня/студента, який досягнув 18-ти річного
                        віку,</span
                      >
                      показати:
                    </p>
                    <ul class="docs-sublist">
                      <li>
                        - нотаріально посвідчену довіреність представника;
                      </li>
                      <li>
                        - документ, що посвідчує особу учня/студента (паспорт
                        або ID картку);
                      </li>
                      <li>
                        - ідентифікаційний номер учня/студента, або довідку про
                        відмову;
                      </li>
                      <li>
                        - документ, що підтверджує право на пільгу (учнівський
                        або студентський квиток);
                      </li>
                      <li>- фото, як на документ, учня/студента;</li>
                    </ul>

                    <p class="mt-3">
                      Консультант зробить фото заявника та оформить заяву на
                      отримання картки.
                    </p>

                    <hr />
                    <p class="docs-sublist__header">
                      При
                      <span>замовленні у освітній установі</span>
                      потрібно показати:
                    </p>
                    <ul class="docs-sublist">
                      <li>
                        - вищеперелічені документи (як при оформленні у центрі
                        обслуговування, включаючи документи представника, якщо
                        він оформляє картку);
                      </li>
                      <li>
                        - фото, як на документ, учня/студента у форматі .jpg;
                      </li>
                      <li>
                        - номер контактного телефону та адресу електронної пошти
                        учня/студента (якщо учню/студенту виповнилось 18 років)
                        або його представника.
                      </li>
                    </ul>
                    <p class="mt-3">
                      Представник навчального закладу оформляє заяву на
                      отримання картки та надає її для перевірки та підпису.
                      Підписану заяву необхідно повернути представнику
                      навчального закладу.
                    </p>
                  </div>
                </li>
                <li>
                  <div class="li-inner">
                    <span
                      >Отримати лист на електронну пошту та оплатити вартість
                      картки</span
                    >
                    <a href="/4ec">на сайті</a>
                    або у терміналі.
                  </div>
                </li>
                <li>
                  <div class="li-inner">
                    <span>Отримати підтвердження про виготовлення картки</span>
                    у SMS або листі на електронній пошті.
                  </div>
                </li>
                <li>
                  <div class="li-inner">
                    <span>Отримати студентську МКВ.</span>
                    <p class="docs-sublist__header">
                      Якщо ви замовляли картку
                      <span>онлайн або у центрі обслуговування,</span>
                      потрібно прийти у обраний день та час у центр
                      обслуговування та звернутись до консультанта.
                    </p>
                    <p class="docs-sublist__header">
                      Якщо картку отримує
                      <span
                        >учень/студент, який на момент оформлення заяви досягнув
                        18-річного віку,</span
                      >
                      показати:
                    </p>
                    <ul class="docs-sublist">
                      <li>- документ, що посвідчує особу учня/студента;</li>
                      <li>
                        - документ, що посвідчує пільгу (учнівський/студентський
                        квиток).
                      </li>
                      <li>- ідентифікаційний номер учня/студента.</li>
                    </ul>
                    <p class="docs-sublist__header">
                      Якщо картку отримує
                      <span
                        >представник учня/студента, який на момент оформлення
                        заяви не досягнув 18-ти річного віку</span
                      >
                      показати:
                    </p>

                    <ul class="docs-sublist">
                      <li>
                        - заяву на отримання картки, підписану представником
                        учня/студента (якщо картка замовлялась на сайті);
                      </li>
                      <li>- документ, що посвідчує особу учня/студента;</li>
                      <li>
                        - документ, що посвідчує пільгу (учнівський/студентський
                        квиток);
                      </li>
                      <li>- ідентифікаційний номер учня/студента.</li>
                      <li>
                        - документ, що посвідчує особу представника
                        учня/студента;
                      </li>
                      <li>
                        - ідентифікаційний номер представника учня/студента.
                      </li>
                    </ul>

                    <p class="docs-sublist__header">
                      Якщо картку отримує
                      <span
                        >представник учня/студента, який досягнув 18-ти річного
                        віку</span
                      >
                      та не в змозі отримати картку самостійно, показати:
                    </p>

                    <ul class="docs-sublist">
                      <li>
                        - нотаріально посвідчену довіреність представника.
                      </li>
                      <li>- документ, що посвідчує особу учня/студента;</li>
                      <li>
                        - документ, що посвідчує пільгу (учнівський/студентський
                        квиток);
                      </li>
                      <li>- ідентифікаційний номер учня/студента.</li>
                      <li>
                        - документ, що посвідчує особу представника
                        учня/студента;
                      </li>
                    </ul>
                    <p class="docs-sublist__header">
                      Якщо Ви замовляли картку
                      <span>у освітній установі,</span>
                      то зверніться до методиста та отримайте картку.
                    </p>
                    <!--<ul class="docs-sublist">
                                            <li>
                                                -  подайте підписану заяву на виготовлення картки;
                                            </li>
                                            <li>
                                                - отримайте картку.
                                            </li>
                    </ul>-->
                  </div>
                </li>
              </ol>
            </div>
            <div class="order-btn">
              <base-button type="button" class="btn" @click="orderCard(1)"
                >Замовити онлайн</base-button
              >
              <!--
              <base-button
                type="button"
                class="btn"
                @click="navigateUrl('/online-queue', '1')"
                >Записатись до електронної черги</base-button
              >
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderCardMixin from "../orderCardsMixin";
import BaseButton from "../../../BaseButton";

export default {
  mixins: [orderCardMixin],
  components: {
    BaseButton,
  },
};
</script>

<style scoped lang="scss" src="./styles.scss"></style>
<style scoped lang="scss" src="../commonStyles.scss"></style>
