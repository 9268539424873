<template>
  <div class="container">
    <div class="row">
      <div class="content">
        <page-header>Тарифи</page-header>
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col" rowspan="2">Тип оплати\Тип транспорту</th>
                <th scope="col" rowspan="2">Пакети</th>
                <th scope="col" rowspan="2">Термін дії пакету</th>
                <th scope="col" colspan="3">Вартість (грн)</th>
              </tr>
              <tr>
                <th>Трамвай Тролейбус</th>
                <th>Автобус</th>
                <th>Приватні перевізники
                  <span>(без пересадки)</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-warning">
                  Квиток у водія
                </td>
                <td>Готівка</td>
                <td>Разовий (без пересадки)</td>
                <td class="text-bold text-center">Не використовується з 1.05.2020р.</td>
                <td class="text-bold text-center">Не використовується з 1.05.2020р.</td>
                <td class="text-bold text-center">
                  <ul>	
                    <li>15 грн. - на усіх маршрутах, крім №25Н (нічний з 22:00 до 6:00), №30А, №30Б та №33А</li>
<li>	18 грн. - на маршрутах №25Н (нічний з 22:00 до 6:00), №30А та №30Б</li>
</ul>
</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td class="text-bold">
                  Банківська картка з можливістю безконтактної оплати, Google
                  Pay чи Apple Pay
                </td>
                <td>Безготівковий</td>
                <td>Разовий (без пересадки)</td>
                <td class="text-bold text-center">12</td>
                <td class="text-bold text-center">15</td>
                <td class="text-bold text-center">-</td>
              </tr>
            </tbody>
            <tbody>
              <tr class="gray">
                <td rowspan="3" class="text-bold">
                  Проїзний тривалого користування (неперсоніфікована картка)
                </td>
                <td>
                  Проїзний 130 поїздок (можливість пересадки продовж 30 хв з
                  моменту оплати проїзду).
                </td>
                <td>30 днів з моменту активації</td>
                <td colspan="2" class="text-bold text-center">750</td>
                <td class="text-bold text-center">-</td>
              </tr>
              <tr class="gray">
                <td>
                  Проїзний "Безлім" (Необмежена кількість поїздок, можливість
                  пересадки упродовж 30 хв з моменту оплати проїзду)
                </td>
                <td>30 днів з моменту активації</td>
                <td colspan="2" class="text-bold text-center">1125</td>
                <td class="text-bold text-center">-</td>
              </tr>
              <tr class="gray">
                <td>Гаманець (гривні)</td>
                <td></td>
                <td class="text-bold text-center">12</td>
                <td class="text-bold text-center">15</td>
                <td class="text-bold text-center">
<ul>	
                    <li>15 грн. - на усіх маршрутах, крім №25Н (нічний з 22:00 до 6:00), №30А, №30Б та №33А</li>
<li>	18 грн. - на маршрутах №25Н (нічний з 22:00 до 6:00), №30А та №30Б</li>
</ul>
                  
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="yellow">
                <td rowspan="2" class="text-bold">Пільгова</td>
                <td>
                  <ul>
                    <li>
                      Пакет "60 поїздок"
                      <a href="/beneficiaries#p_1"
                        >(пп. 1-20, 24,25, 27-33)</a
                      >
                    </li>
                    <li>
                      Пакет "120 поїздок (із супроводом)"
                      <a href="/beneficiaries#p_22">(пп. 21-23, 26)</a>
                    </li>
                   </ul>
                </td>
                <td>Місяць</td>
                <td colspan="2" class="text-bold text-center">
                  <a href="documents/№2918_30_12_2020.pdf" target="_blank"
                    >Гарантує місто</a
                  >
                </td>
                <td class="text-bold text-center">-</td>
              </tr>
              <tr class="yellow">
                <td>Гаманець (гривні) </td>
                <td>
</td>
                <td class="text-bold text-center">12</td>
                <td class="text-bold text-center">15</td>
                <td class="text-bold text-center">
                  <ul>	
                    <li>15 грн. - на усіх маршрутах, крім №25Н (нічний з 22:00 до 6:00), №30А, №30Б та №33А</li>
<li>	18 грн. - на маршрутах №25Н (нічний з 22:00 до 6:00), №30А та №30Б</li>
</ul>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="blue">
                <td rowspan="2" class="text-bold">Студентська</td>
                <td>
                  Проїзний 130 поїздок (можливість пересадки продовж 30 хв з
                  моменту оплати проїзду)
                </td>
                <td>30 днів з моменту активації</td>
                <td colspan="2" class="text-bold text-center">
                  <ul>
                    <li>375 протягом навчального року</li>
                     <li>750 протягом літніх канікул</li>
                    </ul>
                    </td>
                <td class="text-bold text-center">-</td>
              </tr>
              <tr class="blue">
                <td>Гаманець (гривні)</td>
                <td></td>
                <td class="text-bold text-center">12</td>
                <td class="text-bold text-center">15</td>
                <td class="text-bold text-center">
                  <ul>	
                    <li>15 грн. - на усіх маршрутах, крім №25Н (нічний з 22:00 до 6:00), №30А, №30Б та №33А</li>
<li>	18 грн. - на маршрутах №25Н (нічний з 22:00 до 6:00), №30А та №30Б</li>
</ul>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="green">
                <td rowspan="2" class="text-bold">Учнівська</td>
                <td>
                  Проїзний 130 поїздок (можливість пересадки упродовж 30 хв з
                  моменту оплати проїзду)
                </td>
                <td>30 днів з моменту активації</td>
                <td colspan="2" class="text-bold text-center">
                  <ul>
                    <li>225 протягом навчального року</li>
                     <li>750 протягом літніх канікул</li>
                    </ul>
                  
                  </td>
                <td class="text-bold text-center">-</td>
              </tr>
              <tr class="green">
                <td>Гаманець (гривні) </td>
                <td></td>
                <td class="text-bold text-center">12</td>
                <td class="text-bold text-center">15</td>
                <td class="text-bold text-center">
                  <ul>	
                    <li>15 грн. - на усіх маршрутах, крім №25Н (нічний з 22:00 до 6:00), №30А, №30Б та №33А</li>
<li>	18 грн. - на маршрутах №25Н (нічний з 22:00 до 6:00), №30А та №30Б</li>
</ul>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="madgenta">
                <td rowspan="3" class="text-bold">Загальна</td>
                <td>
                  Проїзний 130 поїздок (можливість пересадки продовж 30 хв з
                  моменту оплати проїзду)
                </td>
                <td>30 днів з моменту активації</td>
                <td colspan="2" class="text-bold text-center">750</td>
                <td class="text-bold text-center">-</td>
              </tr>
              <tr class="madgenta">
                <td>
                  Проїзний "Безлім" (необмежена кількість поїздок, можливість
                  пересадки упродовж 30 хв з моменту оплати проїзду)
                </td>
                <td>30 днів з моменту активації</td>
                <td colspan="2" class="text-bold text-center">1125</td>
                <td class="text-bold text-center">-</td>
              </tr>
              <tr class="madgenta">
                <td>Гаманець (гривні)</td>
                <td></td>
                <td class="text-bold text-center">12</td>
                <td class="text-bold text-center">15</td>
                <td class="text-bold text-center">
                  <ul>	
                    <li>15 грн. - на усіх маршрутах, крім №25Н (нічний з 22:00 до 6:00), №30А, №30Б та №33А</li>
<li>	18 грн. - на маршрутах №25Н (нічний з 22:00 до 6:00), №30А та №30Б</li>
</ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>* Зазначені тарифи є орієнтовними та можуть змінитися</p>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../commonComponents/PageHeader";

export default {
  components: {
    PageHeader,
  },
};
</script>

<style scoped src="./style.scss" lang="scss"></style>
