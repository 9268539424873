<template>
    <div class="container">
        <div class="row">
            <div class="content">
                <page-header>
                    Перелік категорій пільговиків,
                </page-header>
                <h5>за проїзд яких проводяться компенсаційні виплати за рахунок коштів місцевого бюджету Вінницької міської територіальної громади</h5>
                <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th scope="col">№ п/п</th>
                        <th scope="col">Перелік пільгових категорій та нормативний акт, у якому визначено право на пільговий проїзд</th>
                        <th scope="col">Документ, що посвідчує право на пільговий проїзд</th>
                        <!--<th scope="col">Кількість безкоштовних поїздок на рік наведена для прикладу</th>-->
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                        <th colspan="4" class="green_bg">Закон України від 22.10.1993 р. №3551-XII «Про статус ветеранів війни, гарантії їх соціального захисту», в т.ч.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><a name="p_1"></a>1</td>
                        <td>учасники бойових дій (стаття 12)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>особи з інвалідністю внаслідок війни (стаття 13) (для осіб з інвалідністю ІІІ групи)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>особи з інвалідністю внаслідок війни (стаття 13) (для осіб з інвалідністю ІІ групи)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>особи з інвалідністю внаслідок війни (стаття 13) (для осіб з інвалідністю І групи)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>постраждалий учасник революції гідності (стаття 12)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                        <th colspan="4" class="green_bg">Закон України від 24.03.1998 р. № 203/98-ВР «Про статус ветеранів військової служби, ветеранів органів внутрішніх справ, ветеранів Національної поліції і деяких інших осіб та їх соціальний захист», в т.ч.:</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>6</td>
                        <td>ветерани військової служби (стаття 6)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>ветерани органів внутрішніх справ (стаття 6)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>ветерани Національної поліції (стаття 6)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>ветерани податкової міліції (стаття 6)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>ветерани державної пожежної охорони (стаття 6)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>ветерани Державної кримінально-виконавчої служби України (стаття 6);</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>ветерани служби цивільного захисту (стаття 6)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>ветерани Державної служби спеціального зв’язку та захисту інформації України (стаття 6)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                        <th colspan="4" class="green_bg">Закон України від 17.04.1991 р. № 962-XII «Про реабілітацію жертв репресій комуністичного тоталітарного режиму 1917-1991 років», в т.ч.:</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>14</td>
                        <td>реабілітовані особи, які отримали інвалідність внаслідок репресій або є пенсіонерами (стаття 6)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>жертви нацистських переслідувань (стаття 6-1)  УБД</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>жертви нацистських переслідувань (стаття 6-2) особам з інвалідністю 1 групи)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>жертви нацистських переслідувань (стаття 6-2) особам з інвалідністю 2 групи)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>жертви нацистських переслідувань (стаття 6-2) особам з інвалідністю 3 групи)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                        <th colspan="4" class="green_bg">Закон України від 28.02.1991 р. № 796-XII «Про статус і соціальний захист громадян, які постраждали внаслідок Чорнобильської катастрофи», в т. ч.:</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>19</td>
                        <td>особи, які постраждали внаслідок Чорнобильської катастрофи, віднесені до категорії 1 (стаття 20)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>учасники ліквідації наслідків аварії на Чорнобильській АЕС, віднесені до категорії 2 (стаття 21)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    <tr>
                        <td>21</td>
                        <td>діти віком від шести років, потерпілі від Чорнобильської катастрофи, яким встановлено інвалідність, пов’язану з Чорнобильською катастрофою (стаття 30)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                        <th colspan="4" class="green_bg">Закон України від 21.03.1991 р. № 875-XII «Про основи соціальної захищеності осіб з інвалідністю в Україні», в т.ч.:</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><a name="p_22"></a>22</td>
                        <td>особи з інвалідністю І групи (по зору та ОРА) (стаття 38-1)</td>
                        <td>Довідка медико-соціальної експертної комісії (МСЕК) </td>
                    </tr>
                    <tr>
                        <td>23</td>
                        <td>особи з інвалідністю І групи (крім зору та ОРА) (стаття 38-1)</td>
                        <td>Довідка медико-соціальної експертної комісії (МСЕК) </td>
                    </tr>
                    <tr>
                        <td>24</td>
                        <td>особи з інвалідністю  ІІ групи (по зору та ОРА) (стаття 38-1)</td>
                        <td>Довідка медико-соціальної експертної комісії (МСЕК) </td>
                    </tr>
                    <tr>
                        <td>25</td>
                        <td>особи з інвалідністю  ІІ групи (крім зору та ОРА)  (стаття 38-1)</td>
                        <td>Довідка медико-соціальної експертної комісії (МСЕК) </td>
                    </tr>
                    <tr>
                        <td>26</td>
                        <td>- діти з інвалідністю віком до шести років (стаття 38-1)</td>
                        <td>Довідка медико-соціальної експертної комісії (МСЕК) </td>
                    </tr>
                    <tr>
                        <td>26</td>
                        <td>- діти з інвалідністю віком до шести років (стаття 38-1)
                            <br>
                            - діти з інвалідністю віком від шести років (стаття 38-1)
                        </td>
                        <td>Завірена копія медичного висновку </td>
                    </tr>

                    </tbody>
                    <thead>
                    <tr>
                        <th colspan="4" class="green_bg">Закон України від 26.04.2001 р. № 2402-III «Про охорону дитинства», в т.ч.:</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>27</td>
                        <td>діти віком від шести років з багатодітних сімей (стаття 13)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                        <th colspan="4" class="green_bg">Закон України від 20.12.1991 р. № 2011-ХІІ «Про соціальний і правовий захист військовослужбовців та членів їх сімей», в т.ч.:</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>28</td>
                        <td>батьки військовослужбовців, які загинули чи померли або пропали безвісти під час проходження військової служби (стаття 14)</td>
                        <td>Посвідчення державного зразка</td>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                        <th colspan="4" class="green_bg">Постанова Кабінету Міністрів України від 17.05.1993 р. № 354 «Про безплатний проїзд пенсіонерів на транспорті загального користування»:</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>29</td>
                        <td>пенсіонери за віком (пункт 1)</td>
                        <td>Пенсійне посвідчення</td>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                        <th colspan="4" class="green_bg">Постанова Кабінету Міністрів України від 16.08.1994 р. №555 «Про поширення чинності постанови Кабінету Міністрів України від 17 травня 1993 р. №354»:</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>30</td>
                        <td>особи з інвалідністю ІІІ групи</td>
                        <td>Пенсійне посвідчення  або посвідчення отримувача державної соціальної допомоги, у якому зазначено групу інвалідності</td>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                        <th colspan="4" class="green_bg">Постанова Кабінету Міністрів України від 05.04.1994 р. № 226 «Про поліпшення виховання, навчання , соціального захисту та матеріального забезпечення дітей сиріт і дітей, позбавлених батьківського піклування»</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>31</td>
                        <td>діти-сироти та діти, позбавлені батьківського піклування, віком від шести років (пункт 11)</td>
                        <td>Єдиний квиток</td>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                        <th colspan="4" class="green_bg">Комплексна програма «Основні напрямки соціальної політики Вінницької міської територіальної громади на 2022-2026 роки», затверджена рішенням Вінницької міської ради від 24.12.2024 р. №715</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>32</td>
                        <td>члени сімей (батьки, вдови (вдівці) та діти, віком від шести років) загиблих, безвісти зниклих за особливих
                            обставин Захисників і Захисниць України та загиблих Героїв України</td>
                        <td>Довідка, видана департаментом соціальної політики міської ради</td>
                    </tr>
                    <tr>
                        <td><a name="p_33"></a>33</td>
                        <td>батьки багатодітних сімей, в яких виховується п’ятеро і більше дітей віком до 18 років</td>
                        <td>Довідка, видана департаментом соціальної політики міської ради</td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from '../commonComponents/PageHeader';

export default {
  components: {
    PageHeader,
  },
};
</script>

<style scoped lang="scss" src="./style.scss"></style>
