<template>
  <div class="buy-offline">
    <div class="container">
      <div class="row">
        <div class="content">
          <page-header>Пункти поповнень і продажу карток</page-header>
          <!-- <h2>
            Можна придбати неперсоніфіковану картку, поповнити МКВ всіх типів,
            актитувати онлайн поповнення, дізнатись пін-код картки
          </h2>
          -->
          <div>
            <button
              class="tabs-btn"
              v-bind:disabled="isDisabled1"
              v-bind:class="{ disabled: isDisabled1 }"
              @click="selectTab(1)"
            >
              Точки поповнення
            </button>
            <button
              class="tabs-btn"
              v-bind:disabled="isDisabled2"
              v-bind:class="{ disabled: isDisabled2 }"
              @click="selectTab(2)"
            >
              Термінали EasyPay
            </button>
            <!-- <button class="tabs-btn" v-bind:disabled=isDisabled3 v-bind:class="{ disabled: isDisabled3 }" @click="selectTab(4)">Кіоски ВТК</button> -->
            <button
              class="tabs-btn"
              v-bind:disabled="isDisabled3"
              v-bind:class="{ disabled: isDisabled3 }"
              @click="selectTab(3)"
            >
              Карта
            </button>

            <div v-if="currentTab == 1" class="bg-grey-light p-8">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Точки поповнення</th>
                      <th scope="col">Адреса</th>
                      <!-- <th scope="col">
                        Можна придбати неперсоніфіковану картку; поповнити МКВ,
                        актитувати онлайн поповнення, дізнатись пін-код картки
                      </th>-->
                    </tr>
                  </thead>
                  <tbody>
                  
                 
                  <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-points.svg`)"
                        />
                      </td>
                      <td>Магазин "Близенько"</td>
                      <td>вулиця Затишна вул., 1 (с.Вінницькі хутори, зуп. мун. автобуса 19)  </td>
                    </tr>
                  


                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsDM.svg`)"
                        />
                      </td>
                      <td>Супермаркет "Домашній маркет"</td>
                      <td>вулиця Академіка Янгеля, 6К, прим.101 (Муніципальний ринок)</td>
                    </tr>
                  <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsDM.svg`)"
                        />
                      </td>
                      <td>Супермаркет "Домашній маркет"</td>
                      <td>вулиця Брацлавська, 10 (Зупинка у сторону міста)</td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsDM.svg`)"
                        />
                      </td>
                      <td>Супермаркет "Домашній маркет"</td>
                      <td>перехрестя вулиць Брацлавська, Довженка</td>
                    </tr>

                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsDM.svg`)"
                        />
                      </td>
                      <td>Супермаркет "Домашній маркет"</td>
                      <td>вулиця Гетьмана Мазепи, 8А</td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsDM.svg`)"
                        />
                      </td>
                      <td>Супермаркет "Домашній маркет"</td>
                      <td>вулиця Київська, 128</td>
                    </tr>

                   
                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsDM.svg`)"
                        />
                      </td>
                      <td>Супермаркет "Домашній маркет"</td>
                      <td>вулиця Нансена, 7А (завод "Сотка")</td>
                    </tr>


                                      <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsDM.svg`)"
                        />
                      </td>
                      <td>Супермаркет "Домашній маркет"</td>
                      <td>вулиця М.Пирогова, 76, прим. 111 (ЖК Пироговський)</td>
                    </tr>

            
                  <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsDM.svg`)"
                        />
                      </td>
                      <td>Супермаркет "Домашній маркет"</td>
                      <td>вулиця Праведників світу, 45А, прим.110 (Новобудова)</td>
                    </tr>
                    

         
                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsDM.svg`)"
                        />
                      </td>
                      <td>Супермаркет "Домашній маркет"</td>
                      <td>вулиця Р.Скалецького, 35 (перехрестя навпроти Бібліотеки)</td>
                    </tr>


                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsDM.svg`)"
                        />
                      </td>
                      <td>Супермаркет "Домашній маркет"</td>
                      <td>вулиця Хмельницьке шосе, 107 (в середині приміщення Західного автовокзала)</td>
                    </tr>
                   

                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsDM.svg`)"
                        />
                      </td>
                      <td>Супермаркет "Домашній маркет"</td>
                      <td>вулиця Якова Шепеля, 72-3 (біля офісу Альянса)</td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-points.svg`)"
                        />
                      </td>
                      <td>Магазин "Mashket"</td>
                      <td>вулиця Миколи Ващука, 8</td>
                    </tr>

                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsOsnova.svg`)"
                        />
                      </td>
                      <td>Супермаркет "Основа"</td>
                      <td>вулиця В'ячеслава Чорновола, 29 (ЖК "Набережний")</td>
                    </tr>

                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsOsnova.svg`)"
                        />
                      </td>
                      <td>Супермаркет "Основа"</td>
                      <td>вулиця Данила Нечая, 164А (Тракторна)</td>
                    </tr>
                    
                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsOsnova.svg`)"
                        />
                      </td>
                      <td>Супермаркет "Основа"</td>
                      <td>вулиця Хмельницьке шосе, 29</td>
                    </tr>


                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsOsnova.svg`)"
                        />
                      </td>
                      <td>Супермаркет "Основа"</td>
                      <td>проспект Юності, 46 (біля "Гранду", внизу перехрестя)</td>
                    </tr>

                     
                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-points.svg`)"
                        />
                      <td>Магазин "Пролісок"</td>
                      <td>
                        вулиця Богдана Хмельницького (с.Вінницькі хутори, зуп.
                        Шевченка)
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsPRO.svg`)"
                        />
                      </td>
                      <td>Супермаркет "PRO/Просто продукти"</td>
                      <td>вулиця Івана Богуна, 71 (зупинка автобуса)</td>
                    </tr>
                    

                    <tr>
                      <td>
                        <img
                          :src="require(`@/assets/replenishment-pointsPRO.svg`)"
                        />
                      </td>
                      <td>Супермаркет "PRO/Просто продукти"</td>
                      <td>вулиця Незалежності, 54А (Вінницькі хутора)</td>
                    </tr>


                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;1</td>
                      <td>
                        Площа Привокзальна
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>


                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;2</td>
                      <td>
                        вулиця Героїв Нацгвардії, (Ц. ринок, просп. Коцюбинського, 32/48)
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>

 
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;3</td>
                      <td>вулиця Замостянська (РАЦС)</td>
                      <!--<td>&check;</td>-->
                    </tr>

                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;4</td>
                      <td>
                        вулиця Замостянська, біля будинку &#8470;8 (біля МПМ
                        "Зоря")
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>

                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;5</td>
                      <td>
                        проспект Коцюбинського (зупинка "Музей Коцюбинського)"
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>
                    
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;6</td>
                      <td>
                        вулиця Келецька, б/н (біля буд. &#8470;118) кінцева
                        тролебуса, навпроти ринку "Вишенька"
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;7</td>
                      <td>проспект Коцюбинського, 32/46 ТЦ "Жовтень"</td>
                      <!--<td>&check;</td>-->
                    </tr>
                  <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;8</td>
                      <td>перехрестя вулиць 600-річчя, Келецька (біля будинку &#8470;52)</td>
                      <!-- <td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;9</td>
                      <td>
                        вулиця Хмельницьке шосе (зуп. «вул. Данила Галицького», біля
                        будинку &#8470;17)
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;10</td>
                      <td>
                        вулиця Київська (зупинка тролейбуса «вулиця Павла Корнелюка»)
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>
                     <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;11</td>
                      <td>вулиця Київська (зуп. «2-га лікарня»)</td>
                      <!--<td>&check;</td>-->
                    </tr>

                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;12</td>
                      <td>вулиця Київська (зупинка тролейбуса «Водоканал»)</td>
                      <!--<td>&check;</td>-->
                    </tr>
                   
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;13</td>
                      <td>
                        перехрестя проспект Космонавтів, вулиця Келецька (навпроти ТЦ "МИР")
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>


                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;14</td>
                      <td>
                        вулиця Соборна (біля будинку &#8470;53А: перехрестя вулиць
                        Соборна, Миколи Оводова)
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>
                   


                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;15</td>
                      <td>вулиця Пирогова (біля входу до ВНМУ ліворуч)</td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;16</td>
                      <td>проспект Юності (б/б &#8470;73/20)</td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;17</td>
                      <td>
                        вулиця Театральна (зуп. «Грушевського», біля будинку
                        &#8470;68)
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;18</td>
                      <td>вулиця Пирогова (зуп. «Електромережа»)</td>
                      <!--<td>&check;</td>-->
                    </tr>
                    
                   
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;19</td>
                      <td>
                        вулиця Соборна, зуп. «MC. DONALDS» (перехрестя вулиць
                        Соборної, Олександра Соловйова)
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;20</td>
                      <td>
                        вулиця Соборна (біля будинку &#8470;12, біля католицького
                        костелу)
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;21</td>
                      <td>перехрестя вулиць Пирогова, Зодчих (зуп. біля будинку &#8470;109)</td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;22</td>
                      <td>
                        перехрестя проспект Коцюбинського, вул. Привокзальна-2, буд. 1
                        (площа Привокзалька)
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;23</td>
                      <td>вулиця Героїв Нацгвардії, б/б 11 (біля магазину Алі Баба)</td>
                      <!--<td>&check;</td>-->
                    </tr>

                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;24</td>
                      <td>
                        проспект Коцюбинського, біля будинку &#8470;4, ВЗТА
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;25</td>
                      <td>
                        площа Привокзальна, буд. 3 (біля тр. зупинки) - цілодобовий
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;26</td>
                      <td>вулиця Матроса Кішки (біля ринку «Урожай»)</td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;27</td>
                      <td>вулиця Хмельницьке шосе (біля будинку &#8470;92)</td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;28</td>
                      <td>вулиця Князів Коріатовичів </td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;29</td>
                      <td>вулиця Київська (зуп. «Друкарня»)</td>
                      <!--<td>&check;</td>-->
                    </tr>
                   <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;30</td>
                      <td>вулиця Батозька (р-н Муніципального ринку)</td>
                      <!--<td>&check;</td>-->
                    </tr>
                <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;31</td>
                      <td>вулиця Левка Лукʼяненка («Мрія»)</td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;32</td>
                      <td>вулиця Немирівське шосе (зуп. «вул. Гетьмана Мазепи»)</td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;33</td>
                      <td>вулиця Хмельницьке шосе (біля тр. зуп. «26 школа»)</td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;34</td>
                      <td>проспект Коцюбинського (біля Будинку офіцерів)</td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;35</td>
                      <td>
                        перехрестя проспекту Коцюбинського, вулиці Замостянська (в напрямку
                        старого міста), біля ринку "Маки"
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>

                   <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;36</td>
                      <td>
                        проспект Коцюбинського, б/н (біля тролейбусної зупинки
                        «вул. Є. Пікуса»)
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>

                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;37</td>
                      <td>вулиця Синьоводська (біля 3-ої лікарні)</td>
                      <!--<td>&check;</td>-->
                    </tr>


                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;38</td>
                      <td>вулиця Левка Лук'яненка</td>
                      <!--<td>&check;</td>-->
                    </tr>
                   
                   
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;39</td>
                      <td>трамвайна зупинка "Медичний університет" (в сторону Вишеньки)</td>
                      <!--<td>&check;</td>-->
                    </tr>
                    
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;40</td>
                      <td>
                        вулиця Чорновола (біля зупинки тролейбусу в напрямку
                        міста)
                      </td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;41</td>
                      <td>вулиця Максима Шимка</td>
                      <!--<td>&check;</td>-->
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/Pressa.svg`)" /></td>
                      <td>Кіоск "Преса" &#8470;42</td>
                      <td>площа Привокзальна, б.1 (біля магазину Сигнал)</td>
                      <!--<td>&check;</td>-->
                    </tr>


                
                    

                   

                 

                 
                  

                  
                   

                   
                   
                   
                    

                  

                   

                  

                  

                          

                      
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="currentTab == 2" class="bg-grey-light p-8">
              <div class="table-responsive">
                   <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Місце розташування</th>
                      <th scope="col">Адреса</th>
                      <!--<th scope="col">Можливість придбання разового квитка</th>-->
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>8 березня</td>
                      <td>8 березня площа, 17а</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТРЦ Мегамол</td>
                      <td>600-річчя вул., 17</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Грош</td>
                      <td>600-річчя вул., 21</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Грош (зал)</td>
                      <td>600-річчя вул., 21</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Грош-2</td>
                      <td>600-річчя вул., 21</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ОККО</td>
                      <td>600-річчя вул., 5</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>600-річчя</td>
                      <td>600-річчя вул., 27 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>600-річчя</td>
                      <td>600-річчя вул., 52</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>магазин Карат</td>
                      <td>600-річчя вул., 52</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Триумф</td>
                      <td>600-річчя вул., 66</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Делікатес</td>
                      <td>Академіка Заболотного вул., 8</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Jardi market</td>
                      <td>Академіка Заболотного вул., 30</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Смак</td>
                      <td>Академіка Павлова вул., 2</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Трамвайна запинка мунiципальний ринок</td>
                      <td>Академіка Янгеля вул., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Приємне побачення</td>
                      <td>Академіка Янгеля вул., 5Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Цілодобовий магазин</td>
                      <td>Академіка Янгеля вул., 6 К</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td></td>
                      <td>Академіка Янгеля вул., 6 К</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td></td>
                      <td>Академіка Янгеля вул., 46</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td></td>
                      <td>Академіка Янгеля вул., 64</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>магазин Варшавський</td>
                      <td>Академіка Янгеля вул., 73</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Домашній маркет-15</td>
                      <td>Академіка Янгеля вул., 73</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td></td>
                      <td>Амосова М. вул., 21</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Форум(зелений)</td>
                      <td>Амосова М. вул., 34</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Українка</td>
                      <td>Амосова М. вул., 34</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Форум</td>
                      <td>Амосова М. вул., 34</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td></td>
                      <td>Андрія Первозванного вул., 6А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Андрія Первозванного вул., 14</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Андрія Первозванного вул., 39</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Світанок</td>
                      <td>Андрія Первозванного вул., 56 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Стахурского 70</td>
                      <td>Андрія Первозванного вул., 70</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td></td>
                      <td>Антонова О. вул., 10</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ДМ Україночка (ліворуч від виходу)</td>
                      <td>Антонова О. вул., 13В</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ДМ Україночка (праворуч від виходу)</td>
                      <td>Антонова О. вул., 13В</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Корона</td>
                      <td>Антонова О. вул., 48а</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td></td>
                      <td>Антонова О. вул., 50</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Гастроном</td>
                      <td>Антонова О. вул., 60а</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Домашній маркет</td>
                      <td>Архітектора Артинова вул., 5</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Ліга</td>
                      <td>Баженова вул.,16</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Удачник</td>
                      <td>Барське шосе 3-й км</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Муниципальний ринок</td>
                      <td>Батозька вул., 2 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>АЗС Chipo</td>
                      <td>Батозька вул., 2 Д</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td></td>
                      <td>Батозька вул., 21</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Батозька вул., 21</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Достаток</td>
                      <td>Бевза І. вул., 11</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td></td>
                      <td>Бойка І. вул., 3</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Брацлавська</td>
                      <td>Брацлавська вул., 1 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Лакомка</td>
                      <td>Брацлавська вул., 10</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>РСЦ МВС (відділення №217/01)</td>
                      <td>Брацлавська вул., 85</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Родинний</td>
                      <td>Бучми вул., 160 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ОККО</td>
                      <td>Василенка К., 19 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Гранд</td>
                      <td>Ващука М. вул., 10</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Домашній маркет-2</td>
                      <td>Ващука М. вул., 14</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Ващука М. вул., 16</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ОсноVа</td>
                      <td>Винниченка вул., 2</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин</td>
                      <td>Вишнева вул., 16</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td></td>
                      <td>Воїнів-Інтернаціоналістів вул., 2Г</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Воїнів-Інтернаціоналістів вул., 4</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td></td>
                      <td>Воїнів-Інтернаціоналістів вул., 12</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td></td>
                      <td>Воїнів- Інтернаціоналістів вул., 14а</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Зупинка</td>
                      <td>Воїнів-Інтернаціоналістів вул., 21</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Волошкова вул., 5А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ЦУМ (вихід ліворуч)</td>
                      <td>Гагаріна пл., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ЦУМ (вихід праворуч біля ліфтів)</td>
                      <td>Гагаріна пл., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Гурман</td>
                      <td>Галицького Д. вул., 27</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Пятихатки</td>
                      <td>Гальчевского Я. вул., 1А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>БРСМ Тимощука</td>
                      <td>Гальчевського Я. вул., 60</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Заправка UPG</td>
                      <td>Генерала Арабея вул., 3А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти Вінницькі хутори</td>
                      <td>Гетьмана Сагайдачного вул., 91А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Гоголя М. вул., 13</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Геркулесик</td>
                      <td>Гонти вул., 2</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Зупинка станцiя вантажна</td>
                      <td>Гонти вул., 34</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Я-Господиня</td>
                      <td>Грушевського М. вул., 56</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТЦ Караван</td>
                      <td>Грушевського М. вул., 56</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Зупинка тролейбуса</td>
                      <td>Грушевського М. вул., 70/8</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Дачна вул., 9</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Домашній маркет 20</td>
                      <td>Довженка О. вул., 36</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Довженка О. вул., 40</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Смакота</td>
                      <td>Дубовецька вул., 35</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Залізнична вул., 32 А</td>
                      <td>Залізнична вул., 32 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Замостянська вул., 1</td>
                      <td>Замостянська вул., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Ярморок</td>
                      <td>Замостянська вул., 25</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Площа Перемоги</td>
                      <td>Замостянська вул., 26</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>БП Ювілейний-2</td>
                      <td>Замостянська вул., 26</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>БП Ювілейний-1</td>
                      <td>Замостянська вул., 26</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Кафе Десерт</td>
                      <td>Замостянська вул., 26</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Наша Ряба</td>
                      <td>Замостянська вул., 28</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Коцюбинского</td>
                      <td>Замостянська вул., 28 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Замостянська вул., 30</td>
                      <td>Замостянська вул., 30</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Зуп. Площа Перемоги</td>
                      <td>Замостянська вул., 31</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Замостянська вул., 34</td>
                      <td>Замостянська вул., 34</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Замостянська вул., 34</td>
                      <td>Замостянська вул., 34</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин АТБ</td>
                      <td>Замостянська вул., 34</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Червоні маки (праворуч від виходу)</td>
                      <td>Замостянська вул., 34 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Червоні маки (ліворуч від виходу)</td>
                      <td>Замостянська вул., 34 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Замостянская</td>
                      <td>Замостянська вул., 40 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Фреш маркет</td>
                      <td>Замостянська вул., 140</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Бар поляна Вінницькі хутори</td>
                      <td>Зелена вул., 48 Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>18 градусов</td>
                      <td>Зерова М. вул., 29</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТРК Поділля City (зал)</td>
                      <td>Зодчих вул., 2</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТРК Поділля City (Сільпо)</td>
                      <td>Зодчих вул., 2</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Мікс продуктів</td>
                      <td>Зодчих вул., 13</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Центральний</td>
                      <td>Зодчих вул., 16</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Jardi market</td>
                      <td>Зодчих вул., 30</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Універсам Вінниця</td>
                      <td>Зодчих вул., 32</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Універсам Вінниця</td>
                      <td>Зодчих вул., 32 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Овочі</td>
                      <td>Зодчих вул., 42</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Зуп. трамв. Лікарня ім. Ющенко</td>
                      <td>Зодчих вул., 113</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Зулінського С., вул., 34 А</td>
                      <td>Зулінського С., вул., 34 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Гастроном На Замості</td>
                      <td>Зулінского С. вул., 39 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Драйв Клуб</td>
                      <td>Зулінського С., вул., 42</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Темп</td>
                      <td>Зулінського С. вул., 43</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин</td>
                      <td>Зулінського С., вул., 46</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Мінімаркет Ліза</td>
                      <td>Зулінського С. вул. зуп. ГПЗ-18</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>зуп. трол. ринок Урожай</td>
                      <td>Келецька вул., 2</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ПродМаг</td>
                      <td>Келецька вул., 37 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Аптека Гаше</td>
                      <td>Келецька вул., 50</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>SHELL</td>
                      <td>Келецька вул., 52</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Келецька вул., 57</td>
                      <td>Келецька вул., 57</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТРЦ МИР /центр вхід</td>
                      <td>Келецька вул., 57</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТРЦ МИР/ ринок</td>
                      <td>Келецька вул., 57</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Люкс</td>
                      <td>Келецька вул., 66</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Аптека Фармавін</td>
                      <td>Келецька вул. 71</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Келецька вул., 78</td>
                      <td>Келецька вул., 78</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Келецька вул., 78</td>
                      <td>Келецька вул., 78</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТРЦ Магігранд</td>
                      <td>Келецька вул., 78 В</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Келецька вул., 83</td>
                      <td>Келецька вул., 83</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>М’ясна лавка</td>
                      <td>Келецька вул., 103 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Домашній маркет -7</td>
                      <td>Келецька вул., 104</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Лайф</td>
                      <td>Келецька вул., 104 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Сільпо</td>
                      <td>Келецька вул., 105</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Київська вул., 106</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Plaza park (зал)</td>
                      <td>Келецька вул., 117 Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Plaza park (вхід)</td>
                      <td>Келецька вул., 117 Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Келецкая-Квятека</td>
                      <td>Келецька вул., 118 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Ринок Вишенька</td>
                      <td>Келецька вул., 122</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Ринок Вишенька</td>
                      <td>Келецька вул., 122 Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Сусідка</td>
                      <td>Келецька вул., 126 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Перлина</td>
                      <td>Келецька вул., 130 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Щедрий Кошик</td>
                      <td>Келецька вул., 132</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Келецька вул., 132</td>
                      <td>Келецька вул., 132</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Агрономічне</td>
                      <td>Київська вул., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Київська вул., 4</td>
                      <td>Київська вул., 4</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Автовокзал</td>
                      <td>Київська вул., 4а</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Вихід на платф.</td>
                      <td>Київська вул., 8</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Центральна АС</td>
                      <td>Київська вул., 8</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Офіс Вінтелепорт</td>
                      <td>Київська вул., 14 Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Київська вул., 18</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Південний буг</td>
                      <td>Київська вул., 27</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Гастроном 0-24</td>
                      <td>Київська вул., 29</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Osnova (ліворуч)</td>
                      <td>Київська вул., 29</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Оsnova (праворуч)</td>
                      <td>Київська вул., 29</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Київська вул., 29 А</td>
                      <td>Київська вул., 29 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Будматеріали-1</td>
                      <td>Київська вул., 29/15</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Київська вул., 43</td>
                      <td>Київська вул., 43</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Аптека</td>
                      <td>Київська вул., 45</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Універсам Київський</td>
                      <td>Київська вул., 49</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Універсам Київський</td>
                      <td>Київська вул., 49</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Київська вул., 54</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>2-а лікарня</td>
                      <td>Київська вул., 66</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Київська вул., 76</td>
                      <td>Київська вул., 76</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Київська вул., 93</td>
                      <td>Київська вул., 93</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Київська вул., 106</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Аптека</td>
                      <td>Київська вул., 112</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Станіславського</td>
                      <td>Київська вул., 112 Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Зупинка коледж №29</td>
                      <td>Київська вул., 120 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Гранд</td>
                      <td>Київська вул., 128 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Київська вул., 128 А</td>
                      <td>Київська вул., 156 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Смакота</td>
                      <td>Клена Ю. вул., 2</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Клена Ю. вул., 5</td>
                      <td>Клена Ю. вул., 5</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Достаток</td>
                      <td>Клена Ю. вул., 28</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Jardi market</td>
                      <td>Князів Коріатовичів вул., 120</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Зупинка тролейбуса</td>
                      <td>Князів Коріатовичів, 135 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Гранд</td>
                      <td>Князів Коріатовичів вул., 149</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Наш</td>
                      <td>Князів Коріатовичів вул., 168 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Князів Коріатовичів вул., 183</td>
                      <td>Князів Коріатовичів вул., 183</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Сусідка</td>
                      <td>Кобилянської О. вул., 4</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Продукти (кінцева зупинка)</td>
                      <td>Кобилянської О., вул</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Провіант</td>
                      <td>Комарова вул., 23</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Основа</td>
                      <td>Константиновича вул., 42а</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Дім продуктів</td>
                      <td>Короленка вул., 7</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Оптовий ринок</td>
                      <td>Короленка вул., 14</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Стрілець</td>
                      <td>Космонавтів просп., 7</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Україночка</td>
                      <td>Космонавтів просп., 20</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Україночка</td>
                      <td>Космонавтів просп., 20</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>18 градусов</td>
                      <td>Космонавтів просп., 24</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Гастроном</td>
                      <td>Космонавтів просп., 24</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Космонавтів просп., 29</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Панорама</td>
                      <td>Космонавтів просп., 30</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Кінотеатр Мир</td>
                      <td>Космонавтів вул., 36 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Колібрі</td>
                      <td>Космонавтів просп., 42</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти НашаРяба (Мир)</td>
                      <td>Космонавтів просп., 42</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Космонавтів просп., 47</td>
                      <td>Космонавтів просп., 47</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Коцюбинського просп., 1</td>
                      <td>Коцюбинського просп., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТЦ Мегамол</td>
                      <td>Коцюбинского просп., 4</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Заупинка Папаніна</td>
                      <td>Коцюбинского просп., 6 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Центральний ринок</td>
                      <td>Коцюбинського просп., 10</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Алібаба</td>
                      <td>Коцюбинського просп., 11 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Центральный ринок</td>
                      <td>Коцюбинського просп., 13</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Коцюбинського просп., 13</td>
                      <td>Коцюбинського просп., 13</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Лайф</td>
                      <td>Коцюбинського просп., 16</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Коцюбинського просп., 21</td>
                      <td>Коцюбинського просп., 21</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Коцюбинського ринок</td>
                      <td>Коцюбинського просп., 30</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ПродуктON</td>
                      <td>Коцюбинського просп., 30</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Жовтень</td>
                      <td>Коцюбинського просп., 30 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Некрасова</td>
                      <td>Коцюбинського просп., 30 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Домашній Маркет</td>
                      <td>Коцюбинського просп., 31 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Коцюбинского просп., 32</td>
                      <td>Коцюбинского просп., 32</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Гастроном Гроно</td>
                      <td>Коцюбинського просп., 35</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТЦ Орнамент</td>
                      <td>Коцюбинського просп., 35</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Чапля вул., квіти</td>
                      <td>Коцюбинського просп., 35</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Гроно</td>
                      <td>Коцюбинського просп., 35</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Домашній маркет-6</td>
                      <td>Коцюбинського просп., 37 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТЦ ПетроЦентр</td>
                      <td>Коцюбинського просп., 40</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Будинок офіцерів</td>
                      <td>Коцюбинського просп., 68 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Петроцентр</td>
                      <td>Коцюбинського просп., 70 А</td>
                      <td></td>
                    </tr>
                                        <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Домашній маркет -9</td>
                      <td>Лебединського вул., 3</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. А-маркет</td>
                      <td>Левка Лукʼяненка вул., 16 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Східна АС</td>
                      <td>Левка Лукʼяненка вул., 18</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Мрія 2</td>
                      <td>Левка Лукʼяненка вул., 22 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Мрія</td>
                      <td>Левка Лукʼяненка вул., 35 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Левка Лукʼяненка вул., 46</td>
                      
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Мега Смак</td>
                      <td>Левка Лукʼяненка вул., 52</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Кооперація</td>
                      <td>Левка Лукʼяненка вул., 98</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин У Бориса-2</td>
                      <td>Лермонтова вул., 173</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин У Бориса</td>
                      <td>Лермонтова вул., 173</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Хімзавод</td>
                      <td>Липовецька вул., 2</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ПродТорг</td>
                      <td>Литвиненко-Вольгельмут М. вул., 25</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Мікс</td>
                      <td>Литвиненко-Вольгельмут М. вул., 30</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Варшавський 3</td>
                      <td>Литвиненко-Вольгемут М. вул., 54</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Смерічка</td>
                      <td>Ломоносова М. вул., 54</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>заправка NG</td>
                      <td>Лугова вул., 3</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Домашній маркет -13</td>
                      <td>Лугова вул., 72</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Магістратська вул., 8</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Зуп. тролейбуса Пл. Героїв Майдану</td>
                      <td>Магістратська вул., 84 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магістратська вул., 98 А</td>
                      <td>Магістратська вул., 98 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магістратська вул., 156</td>
                      <td>Магістратська вул., 156</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Сусідка</td>
                      <td>Майбороди Д., вул., 2 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>У Дома</td>
                      <td>Майбороди Д. вул., 42</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Макаренка вул., 2 А</td>
                      <td>Макаренка вул., 2 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>АЗС Манго</td>
                      <td>Максимовича вул., 43 Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Урожай Аптека</td>
                      <td>Матроса Кішки вул., 7</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Базарчик 2</td>
                      <td>Маяковського вул., 138</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Базарчик</td>
                      <td>Маяковського вул., 138 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Лікарня</td>
                      <td>Маяковського вул., 140 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Маяковського вул.,152 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Малина</td>
                      <td>Маяковського вул., 205</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Повний кошик</td>
                      <td>Мелешка Я. вул., 8</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТЦ Барбара (ліворуч від виходу)</td>
                      <td>Миколаївська вул., 2</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТЦ Барбара (праворуч від виходу)</td>
                      <td>Миколаївська вул., 2</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Миколаївська вул., 4</td>
                      <td>Миколаївська вул., 4</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Домашній</td>
                      <td>Миколайчука І. вул., 19</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Ендокрин. центр (корпус 2)</td>
                      <td>Мічуріна вул., 2</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Московська вул., 41</td>
                      <td>Московська вул., 41</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Авоська</td>
                      <td>Москаленка вул., 65</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Нагірна вул., 12</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Пед. училище</td>
                      <td>Нагірна вул., 13 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Нагірна вул., 19 Л</td>
                      <td>Нагірна вул., 19 Л</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Нагірна вул., 19 С</td>
                      <td>Нагірна вул., 19 С</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин</td>
                      <td>Нансена вул., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Лето</td>
                      <td>Нансена вул., 7А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Фаворит</td>
                      <td>Наскрізний пров., 40</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Перукарня</td>
                      <td>Некрасова вул., 21</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Коровай</td>
                      <td>Некрасова вул., 27</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Наш</td>
                      <td>Некрасова вул., 23 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Green city</td>
                      <td>Некрасова вул., 33</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Некрасова вул., 44</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>АЗС UPG</td>
                      <td>Немирівське шосе, 8</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Домашній маркет-4</td>
                      <td>Немирівське шосе, 23</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Немирівське шосе, 26</td>
                      <td>Немирівське шосе, 26</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Немировское шоссе 62</td>
                      <td>Немирівське шосе, 62</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Немиросвкое шоссе 62 (вулиця)</td>
                      <td>Немирівське шосе, 62а</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Хуторянка</td>
                      <td>Немирівське шосе, 80а</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Заправка БРСМ</td>
                      <td>Немирівське шосе, 84а</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Немирівське шосе, 94</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ДМ Україночка (ліворуч від виходу)</td>
                      <td>Немирівське шоссе, 94е</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Чехова</td>
                      <td>Немирівське шосе, 131</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>заправка БРСМ</td>
                      <td>Немирівське шосе, 203а</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Спокуса</td>
                      <td>Нечая Д. вул., 67 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Бджілка Мая</td>
                      <td>Нечая Д. вул., 102</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Нечая Д. вул., 140</td>
                      <td>Нечая Д. вул., 140</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Продукти</td>
                      <td>Нечая Д. вул., 149</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Продукти</td>
                      <td>Нечая Д. вул., 200</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>SHELL</td>
                      <td>Нечая Д. вул., 220</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Нечая Д. вул., 250 Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Оводова вул., 51</td>
                      <td>Соборна/Оводова</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТЦ SkyPark</td>
                      <td>Оводова вул., 51/24</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>А-Маркет</td>
                      <td>Оводова М. вул., 55</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Фірма В</td>
                      <td>Пирогова вул., 2</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Зупинка тролейбуса</td>
                      <td>Пирогова вул., 5</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Грош Експрес</td>
                      <td>Пирогова вул., 31</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТРЦ Анастасія</td>
                      <td>Пирогова вул., 39</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Пирогова вул., 42</td>
                      <td>Пирогова вул., 42</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Вхід в лікарню</td>
                      <td>Пирогова вул., 42</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Ринок Урожай</td>
                      <td>Пирогова вул., 47</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТРЦ Ізумруд</td>
                      <td>Пирогова вул., 47 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Пирогова вул., 49</td>
                      <td>Пирогова вул., 49</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТЦ Перехід /срібло/</td>
                      <td>Пирогова вул., 49</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Урожай</td>
                      <td>Пирогова вул., 49</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Урожай перехід</td>
                      <td>Пирогова вул., 49</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Урожай Площа</td>
                      <td>Пирогова вул., 49</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Пирогова вул., 49</td>
                      <td>Пирогова вул., 49</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Аптека Конекс</td>
                      <td>Пирогова вул., 52 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Наш</td>
                      <td>Пирогова вул., 52 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Наш</td>
                      <td>Пирогова вул., 52 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Урожай</td>
                      <td>Пирогова вул., 56 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Урожай 2</td>
                      <td>Пирогова вул., 56 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>К-Маркет</td>
                      <td>Пирогова вул., 78</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Дачна</td>
                      <td>Пирогова вул., 89</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Зупинка Дачна</td>
                      <td>Пирогова вул., 89</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Домашний маркет</td>
                      <td>Пирогова вул., 94 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Пирогова вул., 109</td>
                      <td>Пирогова вул., 109</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Домашній маркет -12</td>
                      <td>Пирогова вул., 131</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Електромережа</td>
                      <td>Пирогова вул., 133 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ОККО Електромережа</td>
                      <td>Пирогова вул., 141</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Домашній маркет -3</td>
                      <td>Пирогова вул., 142</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>А Благо</td>
                      <td>Пирогова вул., 151 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Пирогова вул., 157</td>
                      <td>Пирогова вул., 157</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Пирогова вул., 161 А</td>
                      <td>Пирогова вул., 161 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ПромМаг Автофарш</td>
                      <td>Пирогова вул., 166</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>АЗС SHELL</td>
                      <td>Пирогова, вул., 172</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Пирогова вул., 176 А</td>
                      <td>Пирогова вул., 176 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Пирогова вул., 288</td>
                      <td>Пирогова вул., 288</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Писарева вул., 5</td>
                      <td>Писарева вул., 5</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>МАФ</td>
                      <td>Писарєва вул., 6 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТЦ Квартал (вихід)</td>
                      <td>Пікуса Є. вул., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТЦ Квартал (зал)</td>
                      <td>Пікуса Є. вул., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Маркет Fresh</td>
                      <td>Покришкіна вул., 8</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Європейський</td>
                      <td>Покришкіна вул., 8</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>К-маркет</td>
                      <td>Порика В. вул., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Домашній маркет</td>
                      <td>Порика В. вул., 41</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Вокзал 2</td>
                      <td>Привокзальна вул., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Вокзал 4</td>
                      <td>Привокзальна вул., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Вокзал 5</td>
                      <td>Привокзальна вул., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Швидко Гроші</td>
                      <td>Привокзальна вул., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Вхід в ринок</td>
                      <td>Привокзальна вул., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Сигнал</td>
                      <td>Привокзальна вул., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Зупинка троллейбуса</td>
                      <td>Привокзальна вул., 2</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Ринок привокзальний</td>
                      <td>Привокзальна вул., 3</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Привокзальна вул., 3 Д</td>
                      <td>Привокзальна вул., 3 Д</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Продукти</td>
                      <td>Привокзальна вул., 4</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Вокзал 3</td>
                      <td>Привокзальна пл., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Чапля Привокзальна</td>
                      <td>Привокзальна пл., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Пушкіна вул., 4</td>
                      <td>Пушкіна вул., 4</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Продукти</td>
                      <td>Пушкіна вул., 10</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Ратушної Л. вул., 18</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Грош Експрес</td>
                      <td>Ратушної Л. вул., 24 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Ратушної Л. вул., 130 А</td>
                      <td>Ратушної Л. вул., 130 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Домашній маркет</td>
                      <td>Рєпіна вул., 18</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Сабарівське шосе, 1</td>
                      <td>Сабарівське шосе, 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Садова вул., 8</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Салтикова-Щедріна вул., 131</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Банк Форвард</td>
                      <td>Скалецкого Р., вул., 7</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Домашній маркет -1</td>
                      <td>Скалецького Р. вул., 35</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Family market</td>
                      <td>Скалецького Р. вул., 36 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Баня</td>
                      <td>Смирнова Ю. вул., 29</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Авангард</td>
                      <td>Соборна вул., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТЦ Cloud</td>
                      <td>Соборна вул., 10 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Почтамт</td>
                      <td>Соборна вул., 14 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Варшава</td>
                      <td>Соборна вул., 25</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Макдональдс</td>
                      <td>Соборна вул., 51 Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Соборна Чай і Кава</td>
                      <td>Соборна вул., 80</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Козицкого</td>
                      <td>Соборона вул., 53 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Гаманець</td>
                      <td>Станіславського вул., 7</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Стахурського вул., 2 А</td>
                      <td>Стахурського вул., 2 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Стахурського вул., 6 А</td>
                      <td>Стахурського вул., 6 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>МагазинЧик</td>
                      <td>Стахурського вул., 6 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Надія Люкс</td>
                      <td>Стельмаха вул., 3</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Стельмаха</td>
                      <td>Стельмаха вул., 23</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Пан Економ</td>
                      <td>Стеценка вул., 24</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Кондор</td>
                      <td>Стельмаха вул., 51</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Геркулес</td>
                      <td>Стеценка вул., 55</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>МініМаркет</td>
                      <td>Стрелецька вул., 97</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>АЗС БРСМ-НАФТА</td>
                      <td>Стрий-Тернопіль-Кироворгад 377км траса</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Маркет PLUS</td>
                      <td>Стрілецька вул., 1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Домашній Маркет</td>
                      <td>Стрілецька вул., 30А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Продукти Люкс</td>
                      <td>Стрілецька вул., 60 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Гастроном</td>
                      <td>Ступки Б. вул., 2/37</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Ступки Богдана вул., 19</td>
                      <td>Ступки Богдана вул., 19</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Театральна</td>
                      <td>Театральна вул., 9</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Театральна вул., 9</td>
                      <td>Театральна вул., 9</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>РіО</td>
                      <td>Тичини П. вул., 5</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Добро</td>
                      <td>Тімірязєва вул., 27</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Домашній Маркет</td>
                      <td>Толстого Л. вул., 6</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ДМ Україночка</td>
                      <td>Толстого Л. вул., 9</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Трамвайна вул., 3/1</td>
                      <td>Трамвайна вул., 3/1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Наша Ряба</td>
                      <td>Успенського Г. вул., 7</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Формула</td>
                      <td>Успенського Г. вул., 81</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>НГ заправка</td>
                      <td>Успенського Г. вул., 85</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Лето</td>
                      <td>Учительська вул., 12</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Пуд</td>
                      <td>Українки Л. вул., 25</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Маяк</td>
                      <td>Хмельницьке шосе, 101</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Східний вокзал</td>
                      <td>Хмельницьке шосе, 107</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Барский автовокзал</td>
                      <td>Хмельницьке шосе, 107</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Західна АС</td>
                      <td>Хмельницьке шосе, 107</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Маркет 0-24</td>
                      <td>Хмельницьке шосе, 107 Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Західна АС</td>
                      <td>Хмельницьке шосе, 107 Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ОККО Хмельницьке Шосе</td>
                      <td>Хмельницьке шосе, 107 В</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Ізюминка</td>
                      <td>Хмельницьке шосе, 2</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Хмельницьке шосе, 4</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Книжка 1-й поверх</td>
                      <td>Хмельницьке шосе, 7</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ПродТорг</td>
                      <td>Хмельницьке шосе, 11</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Варшава</td>
                      <td>Хмельницьке шосе, 15</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Данила Галицького</td>
                      <td>Хмельницьке шосе, 15 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>зуп. трамв. Палац школярів та юнацтва</td>
                      <td>Хмельницьке шосе, 17</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>зуп. трамв. вул. Д. Галицького</td>
                      <td>Хмельницьке шосе, 17 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>26 школа</td>
                      <td>Хмельницьке шосе, 27 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Аміго</td>
                      <td>Хмельницьке шосе, 35</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Хмельницьке шосе, 75</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТЦ Пассаж (Центр.вход)</td>
                      <td>Хмельницьке шосе, 75 Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>ТК Пасаж</td>
                      <td>Хмельницьке шосе, 75 Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Студентська</td>
                      <td>Хмельницьке шосе, 92 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Студентська</td>
                      <td>Хмельницьке шосе, 93 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Шипс</td>
                      <td>Хмельницьке шосе, 93 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Хмельницьке шосе, 114</td>
                      <td>Хмельницьке шосе, 114</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Продукти</td>
                      <td>Хмельницьке шосе, 124</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>СМ Фуршет</td>
                      <td>Хмельницьке шоссе, 145</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Таможня</td>
                      <td>Хмельницького шосе 7 км</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Зупинка Магазин</td>
                      <td>Цимлянська О. вул., 15</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Черняховського вул., 2А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Черняховського вул., 26 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Черняховського вул., 145</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Маркет 18</td>
                      <td>Чехова вул., 8А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Чехова вул., 9</td>
                      <td>Чехова вул., 9</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магазин Бджілка</td>
                      <td>Чехова вул., 10</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Вітамін</td>
                      <td>Чехова вул., 10Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Грош</td>
                      <td>Чехова вул., 23</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Грош</td>
                      <td>Чехова вул., 23Б</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Чехова вул., 29</td>
                      <td>Чехова вул., 29</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Чехова вул., 56 А</td>
                      <td>Чехова вул., 56 А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Чорновола</td>
                      <td>Чорновола В. вул., 29</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>секція, Набержний квартал</td>
                      <td>Чорновола В. вул., 29</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Чорновола В. вул., 29</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Чумацька вул., 189</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Щедрий кошик</td>
                      <td>Чумацька вул., 258</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Домашній маркет -8</td>
                      <td>Шевченка вул., 5А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Сусідка</td>
                      <td>Шевченка вул., 31</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Шевченка вул., 40</td>
                      <td>Шевченка вул., 40</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Шепеля Я. вул., 9</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Шепеля Я. вул., 5А</td>
                      <td>Шепеля Я. вул., 5А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Шимка М. вул., 24</td>
                      <td>Шимка М. вул., 24</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Грош Експрес</td>
                      <td>Шимка М., вул., 24</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Магнит</td>
                      <td>Широцкого К. вул., 8</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>П'ятачок</td>
                      <td>Ширшова вул., 20</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Ширшова вул., 31</td>
                      <td>Ширшова вул., 31</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Основа</td>
                      <td>Юності просп., 12А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Юності просп., 13</td>
                      <td>Юності просп., 13</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Офіс-центр</td>
                      <td>Юності просп., 18</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Чайка</td>
                      <td>Юності просп., 19</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Юності</td>
                      <td>Юності просп., 20/73</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Чайка</td>
                      <td>Юності просп., 21</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Грош Експрес</td>
                      <td>Юності просп., 22</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>маг. Домашній маркет -11</td>
                      <td>Юності просп., 27</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Гранд</td>
                      <td>Юності просп., 44</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Наша Ряба</td>
                      <td>Юності просп., 45А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Продукти</td>
                      <td>Юності просп., 61А</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><img :src="require(`@/assets/EasyPay1.svg`)" /></td>
                      <td>Гранд</td>
                      <td>Ющенка вул., 5 А</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="currentTab == 3" class="bg-grey-light p-8">
              <ol class="buyOffline__list">
                <div class="map-responsive">
                  <iframe
                    src="https://www.google.com/maps/d/embed?mid=18Eh68Sy208jU_26R8KjqTTRAID5Uke2B"
                    width="640"
                    height="480"
                  ></iframe>

                  <!--   <iframe
                  src="https://www.google.com/maps/d/embed?mid=1a1jN6A8VMXxV0lZtcrZmMvtR117fQeTq"
                  width="800"
                  height="480"
                ></iframe>-->
                </div>
              </ol>
            </div>

             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  
</template>

<script>
import PageHeader from "../commonComponents/PageHeader";

export default {
  props: {
    value: String,
  },
  data() {
    return {
      currentTab: 1,
      isDisabled1: true,
      isDisabled2: false,
      isDisabled3: false,
      isDisabled4: false,
    };
  },
  methods: {
    selectTab(selectedTab) {
      this.currentTab = selectedTab;
      this.isDisabled1 = false;
      this.isDisabled2 = false;
      this.isDisabled3 = false;
      this.isDisabled4 = false;
      if (selectedTab == 1) this.isDisabled1 = true;
      if (selectedTab == 2) this.isDisabled2 = true;
      if (selectedTab == 3) this.isDisabled3 = true;
      if (selectedTab == 4) this.isDisabled4 = true;
    },
  },
  components: {
    PageHeader,
  },
};
</script>

<style scoped lang="scss" src="./style.scss"></style>;
